:root {
  // /* Common styles */
  // --color-white: #ffffff;
  // --color-black: #35435B;
  // --color-orange: #ff4a21;
  // --color-yellow: #008A83;
  // --color-purple: #906de5;
  // --color-red: #f9053e;
  // --color-green: #229558;
  // --color-blue: #039be5;
  // --color-dark-green: #09d395;
  // --color-dark-grey: #666771;
  // /* Background colors */
  // --color-bg-main: #090D27;
  // --color-bg-primary: #181c3a;
  // --color-bg-active: #252a4a;
  // /* Typography colors */
  // --color-text-main: #ffffff;
  // --color-text-secondary: #a1a1a1;
  // --color-text-inactive: #5e5f6a;
  // --color-text-red: #f9053e;
  // --color-text-green: #229558;
  // --color-text-blue: #2fe29b;
  // --color-input: #252a4a;
  // --color-text-footer: #42434e;
  // --color-border-main: #eaeaea;
  // --color-yellow: #ff481c;
  // --color-secondary: #d3d3d3;
}

@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');

// @font-face {
//   font-family: 'Inter', sans-serif;
// }

body * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-stretch: normal;
  font-style: normal;
}
onboard-v2 .container{
  display: none;
}
a,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

.input-error {
  color: var(--color-red);
  font-size: 18px;
}
html{
  font-size: 12px;
  background-color: #0C1111 !important;
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
  #root {
    height: 100%;
  }
}
