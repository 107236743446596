.mobile-modal {
  @media only screen and (max-width: 768px) {
    .close-btn {
      font-size: 12px;
      right: 5px !important;
      top: 5px !important;
    }
    .modal-header {
      height: 64px !important;
      .logo-image {
        width: 68px !important;
        height: 22px !important;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .xs-hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .md-hidden {
    display: none !important;
  }
}
