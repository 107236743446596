@import 'assets/styles/Global.scss';

.switch-wrapper {
  position: relative;
}

.highlight {
  &:hover {
    font-weight: bold;
    color: var(--color-orange) !important;
  }
}
// .switch-wrapper > div {
//     position: absolute;
// }

.Toastify__toast-container {
  left: 0;
  bottom: 0;
  margin-left: 0px;
  padding: 0;

  .Toastify__toast {
    width: 100vw;
    min-height: 52px;
    padding: 0;
    box-shadow: 0 12px 30px rgba(79, 75, 108, 0.159282);
    .Toastify__toast-body {
      display: flex;
      align-items: center;
      margin: 0;
      color: var(--color-text-main);

      .toast_container_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 25px;
        .title {
          color: var(--color-text-main);
          font-weight: bold;
          margin-bottom: 0px;
          margin-right: 10px;
        }
      }

      .success {
        background: var(--color-green);
      }
      .error {
        background: var(--color-red);
      }

      .info {
        background: var(--color-yellow);
      }
    }

    .Toastify__close-button {
      position: absolute;
      // right: 0;
      color: var(--color-text-main);
      opacity: 1;
      top: 15px;
      right: 25px;
      overflow: hidden;
      border: none;
      // padding: 0;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;

      @media only screen and (max-width: 768px) {
        top: 20px;
      }

      @media only screen and (max-width: 500px) {
        top: 5px;
        right: 7px;
      }

      &:hover {
        padding: 25px auto auto 25px;
        background: rgba(48, 51, 51, 0.1);
        color: red;
      }
    }
  }
}

.connect-modal,
.collateral-confirm-modal {
  border-radius: 4px;
  position: relative;
  .ant-modal-content {
    background-color: var(--color-bg-primary);
    border-radius: 4px;
    .ant-modal-body {
      padding: 0;
    }
    @media only screen and (max-width: 768px) {
      width: 80%;
      min-width: 320px;
      margin: auto;
    }
  }
}

button:disabled,
button[disabled] {
  // color: rgba(0, 0, 0, 0.25) !important;
  // background-color: #d3d3d3 !important;
  // background: #d3d3d3 !important;
  // border-color: #d9d9d9 !important;
  // box-shadow: none !important;
}

.ant-select-dropdown-menu-item {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--color-text-main);

  &:hover {
    background-color: #229558 !important;
  }
}

.ant-select-dropdown-menu-item-selected {
  color: var(--color-text-main);
  font-weight: 600;
  background-color: var(--color-bg-primary) !important;
}

input {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    padding-right: 22px;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    padding-right: 22px;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    padding-right: 22px;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    padding-right: 22px;
  }
}

.proposal-detail {
  table {
    border: 1px solid #ccc;
  }

  td,
  th {
    border-top-width: 1px;
    border-top-style: solid; /* double, dashed, dotted... */
    border-top-color: #ccc;
    border-right-width: 1px;
    border-right-style: solid; /* double, dashed, dotted... */
    border-right-color: #ccc;
  }

  a {
    color: #09d395;
  }
}
// Common CSS

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.just-center {
  justify-content: center;
}
.just-between {
  justify-content: space-between;
}
.just-around {
  justify-content: space-around;
}
.just-end {
  justify-content: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.pointer {
  cursor: pointer;
}
.pointer--not-allowed {
  cursor: not-allowed;
}
.full {
  width: 100%;
}
.no-shrink {
  flex-shrink: 0;
}
.w-full {
  width: 100%;
}
.repay-green-revert {
  all: revert;
}

.ant-tabs-nav .ant-tabs-tab-active{
  font-weight: bold !important;
}
